/**
 * ```
 * @prettier
 * ```
 */

// Models
import Product from "@/api/models/product.js";

/**
 * Services
 */
import ProductsService from "@/api/services/resources/products.js";

const productMixin = {
  computed: {
    /**
     * It returns current selected product
     *
     * @returns {object} Selected product
     */
    selectedProduct() {
      return this.$store.getters["product/getSelectedProduct"];
    },
    /**
     * It returns whether products is loading or not
     *
     * @returns {boolean} Products loading state
     */
    isProductLoading() {
      return this.$store.getters["product/getLoading"];
    },
    /**
     * It gives isProductModalOpen status
     *
     * @returns {boolean} Returns isProductModalOpen status
     */
    isProductModalOpen() {
      return this.$store.getters["product/getShowProductModal"];
    },
  },
  methods: {
    /**
     * get price from table
     *
     * @param {boolean} tableIsAvailable table is available
     * @param {Array} priceTable priceTable
     * @param {number} quantity product quantity
     * @param {number} defaultPrice default price
     * @param {boolean} returnInternalPrice return internal price
     * @returns {number} product price
     */
    getPriceFromTable(
      tableIsAvailable,
      priceTable,
      quantity,
      defaultPrice,
      returnInternalPrice = false
    ) {
      if (tableIsAvailable === false) {
        return defaultPrice;
      }
      let packQuantity = this.$store.getters["global/getIsBulk"]
        ? 1
        : this.$store.getters["cart/getPackQuantity"];

      let calculatedQty = quantity * packQuantity;

      let priceFound = 0;

      if (priceTable === null || priceTable === undefined) {
        // true
        return priceFound;
      }
      for (let i = 0; i < priceTable.length; i++) {
        if (
          calculatedQty >= parseFloat(priceTable[i].minimumQuantity) &&
          parseFloat(priceTable[i].maximumQuantity) >= calculatedQty
        ) {
          priceFound =
            (returnInternalPrice
              ? priceTable[i].tsgInternalPrice
              : priceTable[i].customerPrice) || 0;
          break;
        }
      }

      return priceFound;
    },

    /**
     * refresh product
     *
     * @param {object} product Product
     * @param {object} variable variable
     * @param {number} variationId variationId
     * @returns {void}
     */
    async refreshProduct(product, variable, variationId) {
      let defaultVariation = await this.getVariationById(variationId);
      let parent = { parentData: variable };
      defaultVariation = { ...defaultVariation, ...parent };

      //set attributes
      product.setVariationsValues(defaultVariation);
      //set description
      product.setDescription(defaultVariation.parentData.description);
      //set name
      product.name = defaultVariation.parentData.name;
      //set image
      product.setImage(defaultVariation.image);
      //set parent id
      product.parentId = defaultVariation.parentData.id;
      //set type
      product.type = "variation";
      //set enable price table
      product.enablePriceTable = defaultVariation.enablePriceTable;
      //set prices

      product.prices = Object.prototype.hasOwnProperty.call(
        defaultVariation,
        "prices"
      )
        ? defaultVariation.prices
        : [];

      //set parent data
      product.setParentData(defaultVariation.parentData);
      //set id
      product.setId(defaultVariation.id);

      let defaultQty = product.parentData.defaultQuantity
        ? product.parentData.defaultQuantity
        : defaultVariation.parentData.defaultQuantity;

      let quantity =
        this.$store.getters["product/getSelectedProductQuantity"] > 0
          ? this.$store.getters["product/getSelectedProductQuantity"]
          : this.$store.getters["global/getIsBulk"]
          ? defaultQty
          : 1;

      let price = this.getPriceFromTable(
        defaultVariation.enablePriceTable,
        defaultVariation.prices,
        quantity,
        defaultVariation.price
      );

      product.price = defaultVariation.price;
      this.$set(product, "cPrice", Number(price));
      this.$set(product, "cId", defaultVariation.id);
    },

    /**
     * Get variation by id
     *
     * @param {number} id Product id
     * @returns {object} variation product
     */
    async getVariationById(id) {
      return await ProductsService.getVariationById(id);
    },

    /**
     * Get variable product info
     *
     * @param {object} product Product
     * @returns {object} variable product
     */
    async getVariableProduct(product) {
      let id = product.parentId ? product.parentId : product.id;
      return await ProductsService.productVariable(id);
    },

    /**
     * Get variation by attributes
     *
     * @param {object} product Product object
     * @param {number} quantity quantity
     * @param {string} attribute attribute
     * @param {string} attributeValue attributeValue
     * @returns {void}
     */
    async getVariationProductByAttributes(
      product,
      quantity,
      attribute,
      attributeValue
    ) {
      let variationAttrs = {};

      variationAttrs[attribute] = attributeValue;

      //get attributes
      product.variations.forEach((attribute2) => {
        if (attribute2.variation && attribute != attribute2.id) {
          variationAttrs[attribute2.id] = attribute2.selectedItem[0];
        }
      });

      let queryParams = {
        id: product.parentId,
        variation: variationAttrs,
      };

      let foundVariation = await ProductsService.productVariationByAttributes(
        queryParams
      );

      //update selected attribute
      product.variations.forEach((attribute2, index) => {
        if (attribute2.variation && attribute == product.variations[index].id) {
          product.variations[index].selectedItem = [attributeValue];
        }
      });
      //set image
      product.setImage(foundVariation.image);

      //set enable price table
      product.enablePriceTable = foundVariation.enablePriceTable;
      //set prices

      product.prices = Object.prototype.hasOwnProperty.call(
        foundVariation,
        "prices"
      )
        ? foundVariation.prices
        : [];

      let price = this.getPriceFromTable(
        foundVariation.enablePriceTable,
        foundVariation.prices,
        quantity,
        product.price
      );
      product.price = foundVariation.price;
      this.$set(product, "cPrice", Number(price));
      this.$set(product, "cId", foundVariation.id);
      product.setId(foundVariation.id);
    },

    /**
     * Gets price of product via Variation
     * e.g. green could cost more then red
     *
     * @param {object} product Product object
     * @returns {{ id: number, price: string }} _
     */
    async getPriceByVariation(product) {
      return await ProductsService.productVariable(product.id).then(
        (response) => {
          if (response === null) {
            return {
              id: product.id,
              price: product.price,
            };
          }

          return {
            id: response.id,
            price: response.price,
            product: response,
          };
        }
      );
    },

    /**
     * Shows a product via product id
     *
     * @param {number} productId product id
     * @returns {void}
     */
    async showProductViaId(productId) {
      if (!productId) {
        return;
      }
      // Set product loading state to true
      this.$store.dispatch("product/changeLoadingState", true);

      // Get product info
      const product = Object.assign(new Product());
      product.setId(productId);
      const variation = await ProductsService.productVariable(productId);
      this.$store.dispatch("product/changeSelectedProduct", product);
      await this.refreshProduct(product, variation.product, productId);

      // Open product modal
      this.$store.dispatch("product/changeShowProductModal", true);

      // Set product loading state to false
      this.$store.dispatch("product/changeLoadingState", false);

      // Google Analytics Event
      this.$gtag.event("Inform", {
        event_category: "Packbuilder - Inform",
        event_label: product.name,
      });
    },

    /**
     * Sets product param
     *
     * @param {number} productId product id
     * @returns {void}
     */
    setProductParam(productId) {
      this.$router.replace({
        query: { ...this.$route.query, product: productId },
      });

      // emit to parent window
      // we can use wildcard as origin because we are not sending any sensitive data
      window.parent.postMessage(
        {
          type: "product",
          product: {
            id: productId,
          },
        },
        "*"
      );
    },

    /** get selected category info
     *
     * @param {object} categories categories
     * @param {object} foundIndex found product object
     * @param {matchId} matchId found product object
     * @returns {object} category info
     */
    getCategoryobj(categories, foundIndex, matchId) {
      if (Object.keys(foundIndex).length > 0) {
        return foundIndex;
      }
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].subCategories.length > 0) {
          foundIndex = this.getCategoryobj(
            categories[i].subCategories,
            foundIndex,
            matchId
          );

          if (Object.keys(foundIndex).length > 0) {
            return foundIndex;
          }
        }

        if (
          Object.keys(foundIndex).length === 0 &&
          categories[i].id == matchId
        ) {
          foundIndex = {
            index: i,
            obj: categories[i],
          };
          return foundIndex;
        }
      }

      return foundIndex;
    },
  },
};

export default productMixin;
